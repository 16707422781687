// controllers/imagevideoedit_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "contentTypeInputField",
    "imageWrapper",
    "imageInputField",
    "imagePreviewBox",
    "videoWrapper",
    "videoSourceSelect",
    "videoUrlWrapper",
    "videoUrlField",
    "videoUploadField",
    "videoPreviewBox",
  ];

  connect() {
    this.showContent(this.contentTypeInputFieldTarget.value);
  }

  content_type_change(event) {
    const contentType = event.target.value;
    this.toggleContentType(contentType);
  }

  showContent(contentType) {
    // Reset fields visibility and requirements
    this.resetFields();

    if (contentType === "image") {
      // Show the image fields and make the input required
      this.imageWrapperTarget.classList.remove("d-none");
      // this.imageInputFieldTarget.required = true;
    } else if (contentType === "video") {
      this.videoWrapperTarget.classList.remove("d-none");
      this.videoUrlWrapperTarget.classList.remove("d-none");
    }
  }

  toggleContentType(contentType) {
    console.log("inside toggleContentType(contentType)");
    // Reset fields visibility and requirements
    this.resetFields();

    if (contentType === "image") {
      console.log("inside toggleContentType(contentType) image");
      // Show the image fields and make the input required
      this.imageWrapperTarget.classList.remove("d-none");
      // this.imageInputFieldTarget.required = true;
      const isAttached =
        this.imageInputFieldTarget.dataset.imagevideoeditAttached === "true";
      this.imageInputFieldTarget.required = !isAttached;
    } else if (contentType === "video") {
      if (
        this.videoUrlFieldTarget &&
        this.videoUrlFieldTarget.value.trim() !== ""
      ) {
        this.videoWrapperTarget.classList.remove("d-none");
        this.videoUrlWrapperTarget.classList.remove("d-none");
      } else {
        console.log("inside else");
        // Show the video wrapper and the source select initially
        this.videoWrapperTarget.classList.remove("d-none");
        this.videoSourceSelectTarget.classList.remove("d-none"); // Show video source select
        this.videoSourceSelectTarget.required = true;

        // Hide both videoUrlWrapper and videoUploadField until a source is selected
        this.videoUrlWrapperTarget.classList.add("d-none");
        this.videoUploadFieldTarget.classList.add("d-none");
      }
    }
  }

  handleVideoUrlClick() {
    // Clear the video URL field and show video source select
    // this.videoUrlFieldTarget.value = ""; // Clear field by setting it to an empty string

    const selectedSource = this.videoSourceSelectTarget.value;
    this.videoSourceSelectTarget.classList.remove("d-none"); // Show video source select

    if (selectedSource === "Upload") {
      // Show video upload field if source is "Upload"
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUrlWrapperTarget.classList.add("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlFieldTarget.required = false;
    } else {
      // Show video URL wrapper for other sources
      this.videoUrlWrapperTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUploadFieldTarget.required = false;
    }
  }

  handleVideoSourceChange(event) {
    const selectedSource = event.target.value;

    // Reset URL and upload fields visibility and requirements
    this.videoUploadFieldTarget.required = false;
    this.videoUrlFieldTarget.required = false;

    if (selectedSource === "Upload") {
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlWrapperTarget.classList.add("d-none");
    } else if (selectedSource === "Youtube" || selectedSource === "Vimeo") {
      this.videoUrlWrapperTarget.classList.remove("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUrlFieldTarget.placeholder = `Enter ${selectedSource} URL`;
      this.videoUploadFieldTarget.classList.add("d-none");
    } else {
      this.videoUrlWrapperTarget.classList.add("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
    }
  }

  resetFields() {
    // Hide and reset all fields initially
    this.imageWrapperTarget.classList.add("d-none");
    this.videoWrapperTarget.classList.add("d-none");
    this.videoSourceSelectTarget.classList.add("d-none");
    this.videoUrlWrapperTarget.classList.add("d-none");
    this.videoUploadFieldTarget.classList.add("d-none");

    this.imageInputFieldTarget.required = false;
    this.videoSourceSelectTarget.required = false;
    this.videoUrlFieldTarget.required = false;
    this.videoUploadFieldTarget.required = false;
  }

  previewImage(event) {
    const file = event.target.files[0];
    if (file) {
      const preview = document.createElement("img");
      preview.src = URL.createObjectURL(file);
      preview.style.width = "100%";
      preview.style.objectFit = "contain";
      this.imagePreviewBoxTarget.replaceChildren(preview);
    }
  }
}
