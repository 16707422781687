import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper"]

  close(event) {
    event.preventDefault();
    console.log("clicked")
    this.wrapperTarget.classList.add('d-none')
  }

}
