
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["one_time_payment_fields", "subscription_fields", "renewal_block", "total_count_field", "account_sub_plan_currency", "account_sub_plan_amount", "account_plan_currency", "account_plan_amount", "only_otp_visible_field", "account_sub_plan_visible_field", "subscription_type_field", "image_field", "video_field", "image_input_field", "video_input_field", "content_type_input_field"]

  connect() {
    console.log("content_type_input_field", this.content_type_input_fieldTarget.value)
    if (this.content_type_input_fieldTarget.value == "image") {
      this.video_fieldTarget.classList.add('d-none')
      this.image_fieldTarget.classList.remove('d-none')
    } else if (this.content_type_input_fieldTarget.value == "video") {
      this.image_fieldTarget.classList.add('d-none')
      this.video_fieldTarget.classList.remove('d-none')
    }
  }

  one_time_payment(event) {
    console.log(event.target.value)
    console.log("one_time_payment")
    this.one_time_payment_fieldsTarget.classList.remove('d-none')
    this.subscription_fieldsTarget.classList.add('d-none')

    this.renewal_blockTarget.classList.add('d-none')
    this.total_count_fieldTarget.removeAttribute("required")

    this.account_sub_plan_currencyTarget.setAttribute("required", "required")
    this.account_sub_plan_amountTarget.setAttribute("required", "required")

    this.account_plan_currencyTarget.removeAttribute("required")
    this.account_plan_amountTarget.removeAttribute("required")

    this.only_otp_visible_fieldTarget.value = true

    this.account_sub_plan_visible_fieldTarget.value = true
  }

  subscription(event) {
    console.log(event.target.value)
    console.log("subscription")
    this.one_time_payment_fieldsTarget.classList.add('d-none')
    this.subscription_fieldsTarget.classList.remove('d-none')

    this.renewal_blockTarget.classList.remove('d-none')
    this.total_count_fieldTarget.setAttribute("required", "required")

    this.account_sub_plan_currencyTarget.removeAttribute("required")
    this.account_sub_plan_amountTarget.removeAttribute("required")

    this.account_plan_currencyTarget.setAttribute("required", "required")
    this.account_plan_amountTarget.setAttribute("required", "required")

    this.only_otp_visible_fieldTarget.value = false

    this.account_sub_plan_visible_fieldTarget.value = false
  }

  content_type_change(event) {
    event.preventDefault();
    console.log(event.target.value)

    if (event.target.value == "image") {
      this.video_fieldTarget.classList.add('d-none')
      this.image_fieldTarget.classList.remove('d-none')
    } else if (event.target.value == "video") {
      this.image_fieldTarget.classList.add('d-none')
      this.video_fieldTarget.classList.remove('d-none')
    }

  }

}
