// app/javascript/controllers/time_slots_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checklistSlotTemplate", "checklists"];

  connect() {
    console.log("this is connected");
  }

  addSlot(event) {
    event.preventDefault();

    // Count the current visible checklist slots
    const visibleChecklistsCount = Array.from(
      this.checklistsTarget.querySelectorAll(".checklist-slot-fields")
    ).filter((slot) => slot.style.display !== "none").length;

    if (visibleChecklistsCount >= 4) {
      alert("You can only create a maximum of 4 checklists.");
      return;
    }

    // Clone the slot template
    const newSlot = this.checklistSlotTemplateTarget.content.cloneNode(true);

    // Generate a unique ID using a timestamp
    const uniqueId = new Date().getTime();

    // Replace the 'NEW_RECORD' placeholder with this unique ID
    const html = newSlot.firstElementChild.innerHTML.replace(
      /NEW_RECORD/g,
      uniqueId
    );

    newSlot.firstElementChild.innerHTML = html;

    // Append the new slot to the slots container
    this.checklistsTarget.appendChild(newSlot);
  }

  removeSlot(event) {
    event.preventDefault();
    const slot = event.target.closest(".checklist-slot-fields");

    if (slot.querySelector('input[name*="_destroy"]')) {
      slot.querySelector('input[name*="_destroy"]').value = "1";
      slot.style.display = "none"; // Hide the element

      // Remove the required attribute from inputs within this slot
      slot.querySelectorAll("input, textarea, select").forEach((input) => {
        input.required = false;
      });
    } else {
      slot.remove(); // Completely remove newly added slots that aren't persisted yet
    }
  }
}
