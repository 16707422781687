// controllers/image_video_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["videoSourceSelect", "videoUrlField", "videoUploadField"];

  connect() {
    // this.resetFields();
  }

  handleVideoSourceChange(event) {
    const selectedSource = event.target.value;
    this.videoUploadFieldTarget.required = false;
    this.videoUrlFieldTarget.required = false;

    if (selectedSource === "Upload") {
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlFieldTarget.classList.add("d-none");
    } else if (selectedSource === "Youtube" || selectedSource === "Vimeo") {
      this.videoUrlFieldTarget.classList.remove("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUrlFieldTarget.placeholder = `Enter ${selectedSource} URL`;
      this.videoUploadFieldTarget.classList.add("d-none");
    } else {
      this.videoUrlFieldTarget.classList.add("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
    }
  }
}
