
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateFieldContainer", "dateField", "recurringFrequencyWrapper", "recurringFrequencyField", "recurringPeriodWrapper", "recurringPeriodField"]

  connect() {
  }

  send_option(event) {
    console.log(event.target.value)
    if (event.target.value == "false") {
      // this corresponds to send later
      this.dateFieldContainerTarget.classList.remove('d-none')
      this.dateFieldTarget.setAttribute("required", "required")
    } else if (event.target.value == "true") {
      // this corresponds to send now
      this.dateFieldContainerTarget.classList.add('d-none')
      this.dateFieldTarget.removeAttribute("required")
    }
  }

  show_recurrance_options(event) {
    // event.preventDefault();
    console.log(event.target.checked)

    if (event.target.checked) {
      this.recurringFrequencyWrapperTarget.classList.remove('d-none')
      this.recurringPeriodWrapperTarget.classList.remove('d-none')

      this.recurringFrequencyFieldTarget.setAttribute("required", "required")
      this.recurringPeriodFieldTarget.setAttribute("required", "required")
    } else {
      this.recurringFrequencyWrapperTarget.classList.add('d-none')
      this.recurringPeriodWrapperTarget.classList.add('d-none')

      this.recurringFrequencyFieldTarget.removeAttribute("required", "required")
      this.recurringPeriodFieldTarget.removeAttribute("required", "required")
    }

  }



}
