import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loader"]

  show_loader(event) {
    event.preventDefault();
    this.loaderTarget.classList.remove('d-none');
  }
}
