
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["video_url", "video_url_field", "video_file_field", "video_label", "live_session_url_wrapper", "live_session_url_input_field", "date_time_field_wraper", "date_time_input_field", "video_source_field", "video_source_input_field", "edit_video_url_field", "edit_video_url_field_wrapper", "edit_video_source_field_wrapper"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  show_recorded(event) {
    // event.preventDefault();
    // console.log(event.target.tagName)
    // console.log("i am recorded")

    // clear live session url field and session datetime field
    // this.live_session_url_input_fieldTarget.value = ''
    // this.date_time_input_fieldTarget.value = ''

    // remove required attribute from live session url field and session datetime field
    this.live_session_url_input_fieldTarget.removeAttribute("required", "required")
    this.date_time_input_fieldTarget.removeAttribute("required", "required")

    // hide live session url field and session datetime field
    this.live_session_url_wrapperTarget.classList.add('d-none')
    this.date_time_field_wraperTarget.classList.add('d-none')

    // show video source filed and video url/upload field
    this.video_source_fieldTarget.classList.remove('d-none')
    this.video_urlTarget.classList.remove('d-none')

    if (this.hasEdit_video_url_field_wrapperTarget) {
      if (this.edit_video_url_fieldTarget.value == '') {
        this.edit_video_url_field_wrapperTarget.classList.add('d-none')
        this.edit_video_source_field_wrapperTarget.classList.remove('d-none')
      }
      else {
        this.edit_video_url_field_wrapperTarget.classList.remove('d-none')
      }
    }

    // set required attribute for video url field
    console.log(this.video_source_input_fieldTarget.value)
    if (this.video_source_input_fieldTarget.value == "Youtube" || this.video_source_input_fieldTarget.value == "Vimeo") {
      this.video_url_fieldTarget.setAttribute("required", "required")
    } else if (this.video_source_input_fieldTarget.value == "Upload") {
      this.video_file_fieldTarget.setAttribute("required", "required")
    }
  }


  show_live(event) {
    // event.preventDefault();
    // console.log(event.target.tagName)
    // console.log("i am live")

    // clear video_url_field field and video_file_field field
    // this.video_url_fieldTarget.value = ''
    // this.video_file_fieldTarget.value = ''

    // remove required attribute from video_url_field field and video_file_field field
    this.video_url_fieldTarget.removeAttribute("required", "required")
    this.video_file_fieldTarget.removeAttribute("required", "required")

    // hide video_source_field and video_url_field field
    this.video_source_fieldTarget.classList.add('d-none')
    this.video_urlTarget.classList.add('d-none')


    if (this.hasEdit_video_url_field_wrapperTarget) {
      this.edit_video_url_field_wrapperTarget.classList.add('d-none')
    }

    // show live session url field and session datetime field
    this.live_session_url_wrapperTarget.classList.remove('d-none')
    this.date_time_field_wraperTarget.classList.remove('d-none')

    // set required attribute from live session url field and session datetime field
    this.live_session_url_input_fieldTarget.setAttribute("required", "required")
    this.date_time_input_fieldTarget.setAttribute("required", "required")
  }

  video_source(event) {
    console.log(event.target.value)

    if (event.target.value == "Upload") {

      this.video_file_fieldTarget.classList.remove('d-none')
      this.video_url_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')

      this.video_file_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.removeAttribute("required")

    } else if (event.target.value == "Youtube") {

      this.video_url_fieldTarget.classList.remove('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.setAttribute("placeholder", "Paste YouTube link here")


    } else if (event.target.value == "Vimeo") {

      this.video_url_fieldTarget.classList.remove('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.setAttribute("placeholder", "Paste Vimeo link here")


    } else {
      this.video_url_fieldTarget.classList.add('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.add('d-none')

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.removeAttribute("required")
    }
  }
}
