import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "attachment_type_input_field",
    "image_field",
    "video_field",
    "image_input_field",
    "video_input_field",
  ];

  connect() {
    console.log("webinar edit");

    console.log("attachment_type_input_field");
    console.log(
      "attachment_type_input_field" +
        this.attachment_type_input_fieldTarget.value
    );
    if (this.attachment_type_input_fieldTarget.value == "image") {
      this.video_fieldTarget.classList.add("d-none");
      this.image_fieldTarget.classList.remove("d-none");
    } else if (this.attachment_type_input_fieldTarget.value == "video") {
      this.image_fieldTarget.classList.add("d-none");
      this.video_fieldTarget.classList.remove("d-none");
    }
  }

  attachment_type_change(event) {
    event.preventDefault();
    console.log(event.target.value);

    if (event.target.value == "image") {
      this.video_fieldTarget.classList.add("d-none");
      this.image_fieldTarget.classList.remove("d-none");
    } else if (event.target.value == "video") {
      this.image_fieldTarget.classList.add("d-none");
      this.video_fieldTarget.classList.remove("d-none");
    }
  }
}
