// app/javascript/controllers/time_slots_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dayOfWeek", "startDate"];

  connect() {
    this.updateDay({ target: this.startDateTarget });
  }

  updateDay(event) {
    const dateValue = event.target.value;
    if (dateValue) {
      const date = new Date(dateValue);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      this.dayOfWeekTarget.textContent = `(${dayOfWeek})`;
    } else {
      this.dayOfWeekTarget.textContent = "";
    }
  }
}
