import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["templateSelect", "details"];

  connect() {
    this.templateSelect = document.getElementById(
      "account_drip_whatsapp_whatsapp_template_id"
    );
    this.details = document.getElementById("template-details");

    if (this.templateSelect) {
      this.loadTemplateDetails(); // Load details if a template is pre-selected
      this.templateSelect.addEventListener("change", () =>
        this.loadTemplateDetails()
      );
    }
  }

  loadTemplateDetails() {
    const templateId = this.templateSelect.value;

    if (templateId) {
      fetch(`/admin/account_drip_whatsapps/template_details/${templateId}`)
        .then((response) => response.text())
        .then((html) => {
          this.details.innerHTML = html;
        })
        .catch((error) =>
          console.error("Error loading the template details:", error)
        );
    } else {
      this.details.innerHTML = "";
    }
  }
}
