import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["templateSelect", "recipientSelect"];

  connect() {
    this.recipientSelect = document.getElementById("account_drip_whatsapp_to");
    this.templateSelect = document.getElementById(
      "account_drip_whatsapp_whatsapp_template_id"
    );

    if (this.recipientSelect && this.templateSelect) {
      // Check if a recipient is already selected and filter templates accordingly
      if (this.recipientSelect.value) {
        this.filterTemplates();
      }

      // Listen to changes on the recipient select element
      this.recipientSelect.addEventListener("change", () =>
        this.filterTemplates()
      );
    }
  }

  filterTemplates() {
    const recipient = this.recipientSelect.value;

    if (!recipient) {
      this.showAllTemplates();
      return;
    }

    // Fetch templates from the backend using AJAX
    fetch(
      `/admin/account_drip_whatsapps/filter_templates?recipient=${recipient}`
    )
      .then((response) => response.json())
      .then((templates) => {
        // Clear current options
        this.templateSelect.innerHTML =
          '<option value="">Select a template</option>';

        // Populate filtered templates
        templates.forEach((template) => {
          const option = document.createElement("option");
          option.value = template.id;
          option.textContent = template.name;
          // If the template matches the currently selected template, set it as selected
          if (
            option.value ===
            this.templateSelect.getAttribute("data-selected-template")
          ) {
            option.selected = true;
          }
          this.templateSelect.appendChild(option);
        });
      })
      .catch((error) => console.error("Error filtering templates:", error));
  }

  showAllTemplates() {
    this.templateSelect.innerHTML =
      '<option value="">Select a template</option>';
  }
}
