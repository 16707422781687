
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["schedule_fields", "start_date", "no_of_seats"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  show_on_demand(event) {
    // event.preventDefault();
    // console.log(event.target.tagName)

    this.start_dateTarget.value = ""
    // this.no_of_seatsTarget.value = ""

    // remove required
    this.start_dateTarget.removeAttribute("required")
    // this.no_of_seatsTarget.removeAttribute("required")

    this.schedule_fieldsTarget.classList.add('d-none')
  }
  show_scheduled(event) {
    // event.preventDefault();
    // console.log(event.target.tagName)
    // console.log("i am scheduled")

    this.schedule_fieldsTarget.classList.remove('d-none')

    // add required
    this.start_dateTarget.setAttribute("required", "required")
    // this.no_of_seatsTarget.setAttribute("required", "required")

  }
}
