
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["detail", "toggleButton"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  toggle(event) {
    event.preventDefault();
    this.detailTarget.classList.toggle('d-none')
    if (this.detailTarget.classList.contains("d-none")) {
      this.toggleButtonTarget.innerHTML = "View summary"
    } else {
      this.toggleButtonTarget.innerHTML = "Close summary"
    }
  }
}
