// app/javascript/controllers/account_admin/new_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "dateFieldContainer",
    "dateField",
    "recurringFrequencyWrapper",
    "recurringFrequencyField",
    "recurringPeriodWrapper",
    "recurringPeriodField",
    "toSelect",
    "filterSelect",
    "vslFilterSelect",
  ];

  connect() {
    this.setupRecurringFrequencyLimits();
    this.setupToSelectListener();
  }

  send_option(event) {
    const sendNow = event.target.value === "true";
    if (!sendNow) {
      this.dateFieldContainerTarget.classList.remove("d-none");
      this.dateFieldTarget.setAttribute("required", "required");
    } else {
      this.dateFieldContainerTarget.classList.add("d-none");
      this.dateFieldTarget.removeAttribute("required");
    }
  }

  show_recurrance_options(event) {
    if (event.target.checked) {
      this.recurringFrequencyWrapperTarget.classList.remove("d-none");
      this.recurringPeriodWrapperTarget.classList.remove("d-none");

      this.recurringFrequencyFieldTarget.setAttribute("required", "required");
      this.recurringPeriodFieldTarget.setAttribute("required", "required");
    } else {
      this.recurringFrequencyWrapperTarget.classList.add("d-none");
      this.recurringPeriodWrapperTarget.classList.add("d-none");

      this.recurringFrequencyFieldTarget.removeAttribute("required");
      this.recurringPeriodFieldTarget.removeAttribute("required");
    }
  }

  setupRecurringFrequencyLimits() {
    this.recurringFrequencyFieldTarget.addEventListener("input", (event) => {
      const value = parseInt(event.target.value, 10);
      if (value > 12) {
        event.target.value = 12;
      } else if (value < 1) {
        event.target.value = 1;
      }
    });
  }

  setupToSelectListener() {
    this.toSelectTarget.addEventListener("change", (event) => {
      const value = event.target.value;
      if (
        [
          "Leads",
          "LeadsRegisteredForWebinar",
          "LeadsJoinedWebinar",
          "LeadsRegisteredForVsl",
        ].includes(value)
      ) {
        this.filterSelectTarget.style.display = "block";
      } else {
        this.filterSelectTarget.style.display = "none";
      }

      if (value === "LeadsRegisteredForVsl") {
        this.vslFilterSelectTarget.style.display = "block";
      } else {
        this.vslFilterSelectTarget.style.display = "none";
      }
    });
  }
}
