import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("on connect");
    // Ensure the event is only sent once the page is fully loaded
    window.addEventListener("load", () => {
      console.log("on load");
      this.sendWebinarEvent();
    });
  }

  // Function to get cookie value by name
  getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  sendWebinarEvent() {
    // Capture fbc and fbp values set by Facebook
    const fbc = this.getCookieValue("_fbc"); // Typically, Facebook sets this as `_fbc`
    const fbp = this.getCookieValue("_fbp"); // Typically, Facebook sets this as `_fbp`
    const fbLoginId = this.getCookieValue("fb_login_id"); // Retrieve `fb_login_id` if set by Facebook

    // Prepare the data that you need to send to your backend
    const eventData = {
      fbc: fbc,
      fbp: fbp,
      fb_login_id: fbLoginId,
      url: window.location.href, // Current URL
      user_agent: navigator.userAgent, // User agent of the browser
    };

    // Get the URL for sending the event from the HTML data attribute
    const eventUrl = this.element.dataset.eventUrl;

    // Send this data to the Rails backend
    fetch(eventUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(eventData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Event sent successfully:", data);
      })
      .catch((error) => {
        console.error("Error sending event:", error);
      });
  }
}
