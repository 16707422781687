// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "eform", "dform", "text", "cform", "clform", "videourlfield", "editCommentVideoAdd"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  toggle(event) {
    event.preventDefault();
    this.formTarget.classList.toggle('d-none')
  }

  edit_toggle(event) {
    event.preventDefault();
    this.eformTarget.classList.toggle('d-none')
    this.textTarget.classList.toggle('d-none')
  }

  decision_toggle(event) {
    event.preventDefault();
    this.dformTarget.classList.toggle('d-none')
  }

  comment_show(event) {
    event.preventDefault();
    console.log("show comment")
    this.clformTarget.classList.toggle('d-none')
    this.cformTarget.classList.toggle('d-none')
  }

  show_video(event) {
    event.preventDefault();
    console.log("show video")
    this.videourlfieldTarget.classList.toggle('d-none')

  }

  edit_comment_video_toggle(event) {
    event.preventDefault();
    this.editCommentVideoAddTarget.classList.toggle('d-none')
  }
}
