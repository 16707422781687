
import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    console.log("Trying to initialize sortable for a category group...");

    this.sortable = Sortable.create(this.element, {
      handle: ".my-handle",
      draggable: '.card',  // Specify the draggable items class
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    console.log("Drag ended, sending update...");

    let id = event.item.dataset.id;
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    // Get the URL directly
    // let url = this.data.get('url');
    let url = this.element.getAttribute('data-drag-url');
    if (url) {
      Rails.ajax({
        url: url.replace(":id", id),
        type: 'PATCH',
        data: data
      });
    } else {
      console.error("data-drag-url attribute is missing or not set correctly.");
    }
  }


}
