// controllers/imagevideoedit_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "videoSourceSelect",
    "videoUrlWrapper",
    "videoUrlField",
    "videoUploadField",
  ];

  connect() {
    // this.showContent(this.contentTypeInputFieldTarget.value);
  }

  handleVideoUrlClick() {
    console.log("handle clicked");
    // Clear the video URL field and show video source select
    // this.videoUrlFieldTarget.value = ""; // Clear field by setting it to an empty string

    const selectedSource = this.videoSourceSelectTarget.value;
    this.videoSourceSelectTarget.classList.remove("d-none"); // Show video source select

    if (selectedSource === "Upload") {
      // Show video upload field if source is "Upload"
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUrlWrapperTarget.classList.add("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlFieldTarget.required = false;
    } else {
      // Show video URL wrapper for other sources
      this.videoUrlWrapperTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUploadFieldTarget.required = false;
    }
  }

  handleVideoSourceChange(event) {
    const selectedSource = event.target.value;

    // Reset URL and upload fields visibility and requirements
    this.videoUploadFieldTarget.required = false;
    this.videoUrlFieldTarget.required = false;

    if (selectedSource === "Upload") {
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlWrapperTarget.classList.add("d-none");
    } else if (selectedSource === "Youtube" || selectedSource === "Vimeo") {
      this.videoUrlWrapperTarget.classList.remove("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUrlFieldTarget.placeholder = `Enter ${selectedSource} URL`;
      this.videoUploadFieldTarget.classList.add("d-none");
    } else {
      this.videoUrlWrapperTarget.classList.add("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
    }
  }
}
