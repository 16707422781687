
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["instructor_review_price", "one_on_one_consultation_price"]

  instructor_review_required(event) {
    console.log(event.target.checked)
    console.log("instructor_review_required clicked")
    if (event.target.checked) {
      this.instructor_review_priceTarget.setAttribute("required", "required")
    } else {
      this.instructor_review_priceTarget.removeAttribute("required")
    }
  }

  one_on_one_consultation_required(event) {
    console.log("one_on_one_consultation_required clicked")
    if (event.target.checked) {
      this.one_on_one_consultation_priceTarget.setAttribute("required", "required")
    } else {
      this.one_on_one_consultation_priceTarget.removeAttribute("required")
    }
  }



}
