
import { Controller } from "stimulus"
import { DateRangePicker } from 'vanillajs-datepicker'

export default class extends Controller {
  static targets = []

  connect() {
    console.log(DateRangePicker)
    console.log('start date: ', this.element.dataset.startDate)
    console.log('end date: ', this.element.dataset.endDate)
    const elem = document.getElementById('revenue_earned_date_range');
    const rangepicker = new DateRangePicker(elem, {
      // ...options
      format: "dd/mm/yyyy"
    });
  }

}