import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["video_url_wrapper", "video_label", "video_url_field", "video_file_field", "welcome_video_url_wrapper", "welcome_video_label", "welcome_video_url_field", "welcome_video_file_field"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  video_source(event) {
    console.log(event.target.value)
    console.log("custom course controller")

    if (event.target.value == "Upload") {

      this.video_url_wrapperTarget.classList.remove('d-none')

      this.video_file_fieldTarget.classList.remove('d-none')
      this.video_url_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')
      this.video_labelTarget.innerHTML = 'Upload Video'

      this.video_file_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.removeAttribute("required")

    } else if (event.target.value == "Youtube") {

      this.video_url_wrapperTarget.classList.remove('d-none')

      this.video_url_fieldTarget.classList.remove('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')
      this.video_labelTarget.innerHTML = 'Youtube video url'

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.setAttribute("placeholder", "Paste YouTube link here")


    } else if (event.target.value == "Vimeo") {

      this.video_url_wrapperTarget.classList.remove('d-none')

      this.video_url_fieldTarget.classList.remove('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.remove('d-none')
      this.video_labelTarget.innerHTML = 'Vimeo video url'

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.setAttribute("required", "required")
      this.video_url_fieldTarget.setAttribute("placeholder", "Paste Vimeo link here")


    } else {
      this.video_url_wrapperTarget.classList.add('d-none')

      this.video_url_fieldTarget.classList.add('d-none')
      this.video_file_fieldTarget.classList.add('d-none')

      this.video_labelTarget.classList.add('d-none')
      this.video_labelTarget.innerHTML = 'Preview video url'

      this.video_file_fieldTarget.removeAttribute("required")
      this.video_url_fieldTarget.removeAttribute("required")
    }
  }

  welcome_video_source(event) {
    console.log(event.target.value)
    console.log("custom course controller")

    if (event.target.value == "Upload") {

      this.welcome_video_url_wrapperTarget.classList.remove('d-none')

      this.welcome_video_file_fieldTarget.classList.remove('d-none')
      this.welcome_video_url_fieldTarget.classList.add('d-none')

      this.welcome_video_labelTarget.classList.remove('d-none')
      this.welcome_video_labelTarget.innerHTML = 'Upload Video'

      this.welcome_video_file_fieldTarget.setAttribute("required", "required")
      this.welcome_video_url_fieldTarget.removeAttribute("required")

    } else if (event.target.value == "Youtube") {

      this.welcome_video_url_wrapperTarget.classList.remove('d-none')

      this.welcome_video_url_fieldTarget.classList.remove('d-none')
      this.welcome_video_file_fieldTarget.classList.add('d-none')

      this.welcome_video_labelTarget.classList.remove('d-none')
      this.welcome_video_labelTarget.innerHTML = 'Youtube video url'

      this.welcome_video_file_fieldTarget.removeAttribute("required")
      this.welcome_video_url_fieldTarget.setAttribute("required", "required")
      this.welcome_video_url_fieldTarget.setAttribute("placeholder", "Paste YouTube link here")


    } else if (event.target.value == "Vimeo") {

      this.welcome_video_url_wrapperTarget.classList.remove('d-none')

      this.welcome_video_url_fieldTarget.classList.remove('d-none')
      this.welcome_video_file_fieldTarget.classList.add('d-none')

      this.welcome_video_labelTarget.classList.remove('d-none')
      this.welcome_video_labelTarget.innerHTML = 'Vimeo video url'

      this.welcome_video_file_fieldTarget.removeAttribute("required")
      this.welcome_video_url_fieldTarget.setAttribute("required", "required")
      this.welcome_video_url_fieldTarget.setAttribute("placeholder", "Paste Vimeo link here")


    } else {

      this.welcome_video_url_wrapperTarget.classList.add('d-none')

      this.welcome_video_url_fieldTarget.classList.add('d-none')
      this.welcome_video_file_fieldTarget.classList.add('d-none')

      this.welcome_video_labelTarget.classList.add('d-none')
      this.welcome_video_labelTarget.innerHTML = 'Preview video url'

      this.welcome_video_file_fieldTarget.removeAttribute("required")
      this.welcome_video_url_fieldTarget.removeAttribute("required")
    }
  }
}
