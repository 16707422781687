// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

// whatsapp controller
import FilterController from "./whatsapp/filter_controller";
import TemplateDetailsController from "./whatsapp/template_details_controller";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

// whatsapp controllers
application.register("whatsapp--filter", FilterController);
application.register("whatsapp--template-details", TemplateDetailsController);
