import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "videourlfield",
    "linkurlfield",
    "linkUrl",
    "shareData",
    "linkUrlSite",
    "linkUrlTitle",
    "linkUrlDescription",
    "linkUrlImage",
    "title",
    "body",
    "imagesPreview",
    "imageInput",
    "imagePreviewsRemove",
  ];

  connect() {
    console.log("knowledge--form");
    this.typingTimer = null;
    this.doneTypingInterval = 1000; // 1 second
  }

  toggle_video_url(event) {
    event.preventDefault();
    this.videourlfieldTarget.classList.toggle("d-none");
  }

  toggle_link_url(event) {
    event.preventDefault();
    this.linkurlfieldTarget.classList.toggle("d-none");
  }

  paste() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(
      () => this.fetchUrlData(),
      this.doneTypingInterval
    );
  }

  fetchUrlData() {
    this.clearFields();
    const url = this.linkUrlTarget.value;

    fetch(`/fetch-url/grab?url=${encodeURIComponent(url)}`)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        const resultImage = result["image"];
        const resultTitle = result["title"].substring(0, 50);
        const resultDescription = result["description"].substring(0, 120);
        this.shareDataTarget.classList.add("mb-3", "bg-light", "rounded");
        this.shareDataTarget.innerHTML = `
          <img src="${resultImage}" class="w-100">
          <h5 class="mb-0 px-2 pt-2">${resultTitle}</h5>
          <p class="mb-0 px-2 pb-2">${resultDescription}</p>
        `;

        this.linkUrlSiteTarget.value = result["site"];
        this.linkUrlTitleTarget.value = result["title"];
        this.linkUrlDescriptionTarget.value = result["description"];
        this.linkUrlImageTarget.value = resultImage;
        this.titleTarget.value = result["title"];
        this.updateTrixEditor(result["description"]);
      });
  }

  updateTrixEditor(content) {
    console.log("updateTrixEditor");
    const trixEditor = document.querySelector(
      "trix-editor[input='knowledge_article_body']"
    );
    if (trixEditor) {
      console.log("updateTrixEditor: if trixEditor");
      trixEditor.editor.loadHTML(content);
    }
  }

  clearFields() {
    this.shareDataTarget.innerHTML = "";
    this.linkUrlSiteTarget.value = null;
    this.linkUrlTitleTarget.value = null;
    this.linkUrlDescriptionTarget.value = null;
    this.linkUrlImageTarget.value = null;
  }

  previewImages(event) {
    const input = event.target;
    if (input.files) {
      const filesAmount = input.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imgElement = document.createElement("img");
          imgElement.className = "float-left mb-2 mx-2 bg-dark p-0";
          imgElement.style.width = "100px";
          imgElement.style.objectFit = "contain";
          imgElement.src = e.target.result;
          this.imagesPreviewTarget.appendChild(imgElement);
        };
        reader.readAsDataURL(input.files[i]);
      }
      this.imagePreviewsRemoveTarget.classList.remove("d-none");
    }
  }

  removeImagePreviews() {
    this.imageInputTarget.value = "";
    this.imagesPreviewTarget.innerHTML = "";
    this.imagePreviewsRemoveTarget.classList.add("d-none");
  }
}
