Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.subHeading = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false
}



$(document).on("trix-initialize", function (event) {
  console.log("trix initialized")
  const { toolbarElement } = event.target
  const h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]")

  h1Button.insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="subHeading" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
  `)
  h1Button.insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
  `)
})
