// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


import $ from 'jquery';
global.$ = jQuery;
import "controllers"

import '../lib/lightgallery-all'
import '../lib/direct_uploads'
import '../lib/jstz'
import '../lib/trix_customize'
import '../lib/slick'
import '../lib/select2'
