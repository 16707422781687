import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "recurringFrequencyWrapper",
    "recurringFrequencyField",
    "recurringPeriodWrapper",
    "recurringPeriodField",
    "sendEmailField",
    "submitButton",
  ];

  connect() {
    console.log("hello");

    const isChecked = this.sendEmailFieldTarget.checked;
    console.log(this.sendEmailFieldTarget);
    console.log(isChecked);
    if (isChecked) {
      this.submitButtonTarget.dataset.confirm =
        "You are about to send an email to everyone in your Unsolved Network. Are you sure?";
    } else {
      this.submitButtonTarget.dataset.confirm =
        "Are you sure you want to publish this event?";
    }
  }

  removeSpaces(event) {
    let field = event.target;
    field.value = field.value.replace(/\s/g, ""); // Replace spaces with empty string
  }

  show_recurrance_options(event) {
    // event.preventDefault();
    console.log(event.target.checked);

    if (event.target.checked) {
      this.recurringFrequencyWrapperTarget.classList.remove("d-none");
      this.recurringPeriodWrapperTarget.classList.remove("d-none");

      this.recurringFrequencyFieldTarget.setAttribute("required", "required");
      this.recurringPeriodFieldTarget.setAttribute("required", "required");
    } else {
      this.recurringFrequencyWrapperTarget.classList.add("d-none");
      this.recurringPeriodWrapperTarget.classList.add("d-none");

      this.recurringFrequencyFieldTarget.removeAttribute(
        "required",
        "required"
      );
      this.recurringPeriodFieldTarget.removeAttribute("required", "required");
    }
  }

  updateConfirmMessage(event) {
    const isChecked = this.sendEmailFieldTarget.checked;
    console.log(this.sendEmailFieldTarget);
    console.log(isChecked);
    if (isChecked) {
      this.submitButtonTarget.dataset.confirm =
        "You are about to send an email to everyone in your Unsolved Network. Are you sure?";
    } else {
      this.submitButtonTarget.dataset.confirm =
        "Are you sure you want to publish this event?";
    }
  }
}
