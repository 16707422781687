
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["videourlfield", "linkurlfield"]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  toggle_video_url(event) {
    event.preventDefault();
    this.videourlfieldTarget.classList.toggle('d-none')
  }
  toggle_link_url(event) {
    event.preventDefault();
    this.linkurlfieldTarget.classList.toggle('d-none')
  }
}
