import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = ["messages", "newMessage", "chatwrapper", "chatmemberswrapper"]

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "MessageChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {
    this.scrollToBottom()
  }

  _disconnected() {
  }

  _received(data) {
    if (data.message) {
      this.messagesTarget.insertAdjacentHTML('beforeend', data.message)
      this.scrollToBottom()

      if (!document.hidden) {
        this.subscription.perform("touch")
      }
    }
  }

  clearMessage(event) {
    this.newMessageTarget.value = ''
  }

  scrollToBottom() {
    var element = document.querySelector('.chat-panel')
    element.scrollTop = element.scrollHeight
    element.scrollIntoView({ block: "end" })
  }

  showMembers(event) {
    event.preventDefault();
    this.chatmemberswrapperTarget.classList.remove('d-none')
    // this.chatmemberswrapperTarget.classList.remove('chat-group-members__close')
    // this.chatmemberswrapperTarget.classList.add('chat-group-members__open')
    this.chatwrapperTarget.classList.add('d-none')
  }

  hideMembers(event) {
    event.preventDefault();
    // this.chatmemberswrapperTarget.classList.remove('chat-group-members__open')
    // this.chatmemberswrapperTarget.classList.add('chat-group-members__close')
    this.chatmemberswrapperTarget.classList.add('d-none')
    this.chatwrapperTarget.classList.remove('d-none')
  }
}
